import React from 'react';
import posthog from 'posthog-js';
import Layout from './src/components/layout/Layout';

export function wrapPageElement({ props, element }) {
  return <Layout {...props}>{element}</Layout>;
}

export function onClientEntry() {
  posthog.init('phc_PzRCqSWdsBEHE0erF47mtDV2gHkySjS2pmjPrLY1HXw', {
    api_host: 'https://eu.i.posthog.com',
    person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
  });
}

export function onRouteUpdate({ location }) {
  if (location.pathname === '/eng/c/24/assist/') {
    posthog.capture('$pageView');
  }
}
