import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';

const SidedrawerStyles = styled.div`
  height: 100vh;
  width: 100%;
  max-width: 600px;
  background-color: whitesmoke;
  position: fixed;
  top: 0;
  /* left needs altering to a minus */
  left: -800px;
  opacity: 0;
  pointer-events: none;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;

  &.open {
    left: 0px;
    opacity: 1;
    pointer-events: all;
  }

  .sidedrawer-nav {
    display: block;
    width: 100%;
    a {
      display: block;
      padding: 15px 0;
      width: 100%;
      text-align: center;
      color: #999;
      border-bottom: 1px solid #999;

      &:last-of-type {
        border-bottom: none;
      }
    }
    a:hover {
      box-shadow: none;
      color: var(--accent);
    }
  }
`;

export default function Sidedrawer({ click, shown }) {
  const classes = shown ? 'open' : '';

  return (
    <SidedrawerStyles className={classes}>
      <nav className="sidedrawer-nav">
        <Link to="/">Home</Link>

        <Link to="/about" onClick={click}>
          About Us
        </Link>

        <Link to="/delivery" onClick={click}>
          Delivery Centres
        </Link>

        <Link to="/sustainable" onClick={click}>
          Sustainability
        </Link>

        <Link to="/team" onClick={click}>
          Leadership Team
        </Link>

        <Link to="/services" onClick={click}>
          Services
        </Link>

        <Link to="/news" onClick={click}>
          News
        </Link>

        <Link to="/faqs" onClick={click}>
          Frequently Asked Questions
        </Link>

        <Link to="/contact" onClick={click}>
          Contact Us
        </Link>
      </nav>
    </SidedrawerStyles>
  );
}

Sidedrawer.propTypes = {
  click: PropTypes.func,
  shown: PropTypes.bool,
};
