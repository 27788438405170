import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import SEO from './SEO';

// import 'normalize.css';

import GlobalStyle from '../../styles/GlobalStyle';
import Typography from '../../styles/Typography';
import Topbar from './Topbar';
import Header from './Header';
import Footer from './Footer';

import EngineerHeader from './EngineerHeader';

const LAYOUTQUERY = graphql`
  {
    settings: sanitySiteSettings {
      email
      phone
      name
      logo {
        asset {
          gatsbyImageData(height: 100)
        }
      }
    }

    homepage: sanityHomepage {
      hero {
        tagline
      }
    }

    services: allSanityServices(sort: { fields: name, order: ASC }) {
      nodes {
        id
        name
        slug {
          current
        }
        blurb {
          en
        }
        image {
          asset {
            url
          }
        }
      }
    }
  }
`;

export default function Layout({ children, location }) {
  const { settings, homepage, services } = useStaticQuery(LAYOUTQUERY);

  // The follow effects the root layout on the /enginner route
  if (location.pathname.includes('/eng/c')) {
    return (
      <>
        <GlobalStyle />
        <Typography />
        <SEO />
        {/* <h5>** This page is currently in dev **</h5> */}

        <EngineerHeader logo={settings.logo.asset} altText={settings.name} />

        <main>{children}</main>

        <Footer
          companyName={settings.name}
          footerText={homepage.hero.tagline}
          services={services.nodes}
        />
      </>
    );
  }

  return (
    <>
      <GlobalStyle />
      <Typography />
      <SEO />

      <Topbar
        currentPage={location.pathname}
        email={settings.email}
        phone={settings.phone}
      />

      <Header logo={settings.logo.asset} altText={settings.name} />

      <main>{children}</main>

      <Footer
        companyName={settings.name}
        footerText={homepage.hero.tagline}
        services={services.nodes}
      />
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
};
