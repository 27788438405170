import React, { useEffect, useState } from 'react';
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Navigation from './Navigation';

const HeaderStyles = styled.header`
  position: sticky;
  top: 0;
  width: 100vw;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 0px !important;
  z-index: 10000;
  padding: 12px 0;
  transition: 0.3s ease-in-out;
  .flex {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .gatsby-image-wrapper {
    transition: 0.3s ease-in-out;
  }

  &.scrolled {
    padding: 5px 0;
    .gatsby-image-wrapper {
      object-fit: contain;
      max-height: 50px;
    }
  }
  // place the logo center and menu below on ipad screen and lower
  @media only screen and (max-width: 600px) {
    .gatsby-image-wrapper {
      width: 100%;
      margin: 0 auto;
    }
  }
`;

export default function Header({ logo, altText }) {
  const image = getImage(logo);
  const src = getSrc(logo);

  const [isScrolled, setIsScrolled] = useState(false);
  const classes = isScrolled ? 'scrolled' : '';

  useEffect(() => {
    // this is used to resize the header on page scroll
    function handleScroll(event) {
      event.preventDefault();

      if (window.scrollY >= 25) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  });

  return (
    <HeaderStyles className={classes}>
      <div className="container flex">
        <Link to="/">
          <GatsbyImage
            image={image}
            alt={altText}
            src={src}
            imgStyle={{ objectFit: 'contain', transistion: 'ease-in-out .3s' }}
          />
        </Link>

        <Navigation />
      </div>
    </HeaderStyles>
  );
}

Header.propTypes = {
  logo: PropTypes.object.isRequired,
  altText: PropTypes.string.isRequired,
};
