import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

const FOOTER_ICON_QUERY = graphql`
  query {
    settings: sanitySiteSettings {
      icons: companyIcons {
        _key
        logo {
          asset {
            gatsbyImageData(height: 150)
          }
        }
        altText
      }
    }
  }
`;

const IconsStyled = styled.div`
  background: white;
  padding: 60px 0;

  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 50px;
    align-items: center;
    justify-content: center;
  }
`;

export default function FooterLogos() {
  const { settings } = useStaticQuery(FOOTER_ICON_QUERY);

  // if there are no icons then show nothing
  if (!settings.icons || settings.icons.length === 0) {
    return '';
  }

  return (
    <IconsStyled>
      <div className="container grid-container">
        {settings.icons.map((icon) => {
          const logo = getImage(icon.logo.asset);
          return (
            <GatsbyImage
              key={icon._key}
              image={logo}
              alt={icon.altText}
              objectFit="contain"
            />
          );
        })}
      </div>
    </IconsStyled>
  );
}
