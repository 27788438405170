import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const BurgerStyles = styled.button`
  border: 0;
  display: none;
  margin: 1rem 1rem;
  color: none;
  background-color: transparent;
  cursor: pointer;
  pointer-events: none;
  max-width: 40px;
  overflow: none;

  &:focus,
  &:active,
  &:hover,
  &:visited {
    color: transparent;
  }

  div {
    width: 35px;
    height: 2px;
    background-color: var(--accent);
    margin: 10px;
    border-radius: 6px;
  }

  @media only screen and (max-width: 780px) {
    display: block;
    pointer-events: all;
  }
`;

export default function Burger({ click }) {
  return (
    <BurgerStyles className="burger-button" type="button" onClick={click}>
      <div />
      <div />
      <div />
    </BurgerStyles>
  );
}

Burger.propTypes = {
  click: PropTypes.func,
};
