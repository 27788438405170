// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-delivery-jsx": () => import("./../../../src/pages/delivery.jsx" /* webpackChunkName: "component---src-pages-delivery-jsx" */),
  "component---src-pages-eng-c-24-assist-jsx": () => import("./../../../src/pages/eng/c/24/assist.jsx" /* webpackChunkName: "component---src-pages-eng-c-24-assist-jsx" */),
  "component---src-pages-eng-codes-jsx": () => import("./../../../src/pages/eng/codes.jsx" /* webpackChunkName: "component---src-pages-eng-codes-jsx" */),
  "component---src-pages-faqs-jsx": () => import("./../../../src/pages/faqs.jsx" /* webpackChunkName: "component---src-pages-faqs-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-support-index-js": () => import("./../../../src/pages/support/index.js" /* webpackChunkName: "component---src-pages-support-index-js" */),
  "component---src-pages-sustainable-jsx": () => import("./../../../src/pages/sustainable.jsx" /* webpackChunkName: "component---src-pages-sustainable-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/Post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-single-service-jsx": () => import("./../../../src/templates/SingleService.jsx" /* webpackChunkName: "component---src-templates-single-service-jsx" */)
}

