import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FaPhoneSquareAlt } from 'react-icons/fa';
import { MdOutlineAlternateEmail } from 'react-icons/md';

const TopbarStyles = styled.div`
  width: 100vw;
  max-width: 100%;
  background-color: #e9e9e9;
  color: #999;
  padding: 12px 0;
  box-sizing: border-box;
  &.dark {
    background-color: #262626;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .socials {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  p {
    font-size: 14px;
    margin: 0;
    padding: 0;
    color: #999;
    display: inline-block;
  }
  svg {
    height: 12px;
  }
  .contact-info {
    span {
      &::after {
        content: '|';
        padding: 10px;
      }
    }
    span:last-child::after {
      content: '';
    }
    p {
      padding-left: 5px;
    }
  }
  // hide the topbar on ipad screens and smaller
  @media only screen and (max-width: 780px) {
    display: none;
    pointer-events: none;
  }
`;

export default function Topbar({ currentPage, phone, email }) {
  const topbarClass = currentPage === '/' ? '' : 'dark';
  return (
    <TopbarStyles className={topbarClass}>
      <div className="container flex">
        <div className="contact-info">
          <span className="phone">
            <FaPhoneSquareAlt />
            <a href={`tel:${phone}`}>
              <p>{phone}</p>
            </a>
          </span>

          <span className="phone">
            <MdOutlineAlternateEmail />
            <a href={`mailto:${email}`}>
              <p>{email}</p>
            </a>
          </span>
        </div>

        <div className="socials" />
        {/* need to add social media links here if they are provided */}
      </div>
    </TopbarStyles>
  );
}

Topbar.propTypes = {
  currentPage: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
};
