import { createGlobalStyle } from 'styled-components';

const Typography = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

    h1, h2, h3, h4, h5, h6 {
        font-family: 'Montserrat', sans-serif !important;
        color: var(--heading);
    }

    p, a, li, button {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 30px;
        font-family:  -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        /* or 187% */
        letter-spacing: 0.2px;
        color: #2D334A;
        font-feature-settings: normal;
        font-kerning: auto;
        font-language-override: normal;
        font-optical-sizing: auto;
        font-size: 16px;
        font-size-adjust: none;
        font-stretch: 100%;
        font-style: normal;
        font-variant: normal;
        font-variant-alternates: normal;
        font-variant-caps: normal;
        font-variant-east-asian: normal;
        font-variant-ligatures: normal;
        font-variant-numeric: normal;
        font-variant-position: normal;
        font-variation-settings: normal;
        font-weight: 400;
        line-height: 28px;
        -moz-osx-font-smoothing: grayscale;
    }
`;

export default Typography;
