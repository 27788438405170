import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    :root {
        --heading: #2b2b2b;
        --white: #FFFFFE;
        --accent: #407056;
        --grey: #F3F4F6;
        --darkGrey: ##999;
        --lightGrey: #E7E7E7;
    }
    *, *::before, *::after {
        box-sizing: border-box;
    }
    html {
        scroll-behavior: smooth;
    }
    body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    a:link {
        text-decoration: none;
    }
    a:visited {
        color: inherit;
    }
    a:focus {
        color: var(--accent);
    }
    a:hover {
        color: inherit;
        color: var(--accent);
    }
    a:active {}
    .container {
        width: 95%;
        max-width: 1180px;
        margin: 0 auto;
    }
    .container-small {
        width: 95%;
        max-width: 960px;
        margin: 0 auto;
    }
`;

export default GlobalStyle;
