import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export default function CallToAction({ children }) {
  return (
    <CTAStyles>
      <span>{children}</span>
    </CTAStyles>
  );
}

CallToAction.propTypes = {
  children: PropTypes.any,
};

const CTAStyles = styled.button`
  background-color: var(--accent);
  border-bottom-color: rgb(255, 255, 255);
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgb(255, 255, 255);
  border-left-style: none;
  border-left-width: 0px;
  border-right-color: rgb(255, 255, 255);
  border-right-style: none;
  border-right-width: 0px;
  border-top-color: rgb(255, 255, 255);
  border-top-style: none;
  border-top-width: 0px;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-feature-settings: normal;
  font-kerning: auto;
  font-language-override: normal;
  font-optical-sizing: auto;
  font-size: 14px;
  font-size-adjust: none;
  font-stretch: 100%;
  font-style: normal;
  font-variant: normal;
  font-variant-alternates: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
  font-variant-position: normal;
  font-variation-settings: normal;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 24px;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  outline-color: rgb(255, 255, 255);
  outline-style: none;
  outline-width: 0px;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-bottom: 15px;
  padding-left: 36px;
  padding-right: 36px;
  padding-top: 15px;
  position: relative;
  text-align: center;
  text-decoration: rgb(255, 255, 255);
  text-decoration-color: rgb(255, 255, 255);
  text-decoration-line: none;
  text-decoration-style: solid;
  text-indent: 0px;
  text-rendering: optimizelegibility;
  touch-action: manipulation;
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
  vertical-align: baseline;
  visibility: visible;
  white-space: normal;
  z-index: 1;
  -moz-osx-font-smoothing: grayscale;
  &:hover {
    cursor: pointer;
  }
  &[disabled] {
    opacity: 40%;
    pointer-events: none;
  }
  span {
    border-bottom-color: rgb(255, 255, 255);
    border-bottom-style: none;
    border-bottom-width: 0px;
    border-image-outset: 0;
    border-image-repeat: stretch;
    border-image-slice: 100%;
    border-image-source: none;
    border-image-width: 1;
    border-left-color: rgb(255, 255, 255);
    border-left-style: none;
    border-left-width: 0px;
    border-right-color: rgb(255, 255, 255);
    border-right-style: none;
    border-right-width: 0px;
    border-top-color: rgb(255, 255, 255);
    border-top-style: none;
    border-top-width: 0px;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    font-family: 'Montserrat', sans-serif;
    font-feature-settings: normal;
    font-kerning: auto;
    font-language-override: normal;
    font-optical-sizing: auto;
    font-size: 14px;
    font-size-adjust: none;
    font-stretch: 100%;
    font-style: normal;
    font-variant: normal;
    font-variant-alternates: normal;
    font-variant-caps: normal;
    font-variant-east-asian: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-variant-position: normal;
    font-variation-settings: normal;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 24px;
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    outline-color: rgb(255, 255, 255);
    outline-style: none;
    outline-width: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    text-align: center;
    text-indent: 0px;
    text-rendering: optimizelegibility;
    vertical-align: baseline;
    visibility: visible;
    white-space: normal;
    -moz-osx-font-smoothing: grayscale;
    text-transform: uppercase;
  }
`;
