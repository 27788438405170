import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

const HELMETQUERY = graphql`
  {
    settings: sanitySiteSettings {
      name
      logo {
        asset {
          url
        }
      }
    }

    homepage: sanityHomepage {
      hero {
        tagline
      }
    }
  }
`;

export default function SEO({
  children,
  location,
  description,
  title,
  image,
  index,
}) {
  const { settings, homepage } = useStaticQuery(HELMETQUERY);

  return (
    <Helmet titleTemplate={`%s - ${settings.name}`}>
      <html lang="en" />
      <title>{title}</title>

      <meta name="robots" content={index === false ? 'noidex, nofollow' : ''} />

      {/* favicon */}

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#569076" />
      <meta name="theme-color" content="#ffffff" />

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap"
        rel="stylesheet"
      />

      {/* meta tags */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      <meta charSet="UTF-8" />
      <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />
      <meta name="description" content={description || homepage.hero.tagline} />

      {/* open graph */}
      {location && <meta property="og:url" content={location} />}
      <meta property="og:image" content={image || settings.logo.asset.url} />
      <meta property="og:title" content={title} key="ogtitle" />
      <meta proprty="og:site_name" content={settings.name} key="ogsitename" />
      <meta
        property="og:description"
        content={description || homepage.hero.tagline}
        key="ogdescription"
      />
    </Helmet>
  );
}

SEO.propTypes = {
  children: PropTypes.any,
  location: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  index: PropTypes.bool,
};
