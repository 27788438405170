import React from 'react';
import styled from 'styled-components';
import { Link, graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import Bar from '../../images/bar.jpg';
import CallToAction from '../CallToAction';
import icon from '../../images/uksi.png';
import FooterLogos from './FooterLogos';

const FooterStyles = styled.footer`
  width: 100vw;
  background: url('https://autora-react.vercel.app/assets/img/page/bg-footer.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .grid {
    padding: 60px 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 30px;
  }

  h3 {
    margin: 0;
    padding-bottom: 15px;
    font-size: 16px;
    text-transform: uppercase;
    color: white;
  }
  p {
    box-sizing: border-box;
    color: rgb(153, 153, 153);
    font-family: 'Montserrat', sans-serif;

    font-language-override: normal;
    font-optical-sizing: auto;
    font-size: 14px;
    font-size-adjust: none;
    font-stretch: 100%;
    font-style: normal;
    font-variant: normal;
    font-variant-alternates: normal;
    font-variant-caps: normal;
    font-variant-east-asian: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-variant-position: normal;
    font-variation-settings: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 15px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    outline-color: rgb(153, 153, 153);
    outline-style: none;
    outline-width: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    text-rendering: optimizelegibility;
    vertical-align: baseline;
    -moz-osx-font-smoothing: grayscale;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    a {
      padding: 15px 0 0 5px;
      display: block;
      font-size: 14px;
      transition: ease-in-out 0.3s;
      color: var(--white);
      &:hover {
        color: var(--accent);
      }
    }
  }

  .column1 {
    padding: 0 15px 0 0;
    img {
      max-height: 100px;
      margin-bottom: 30px;
    }
  }

  .bar {
    background-image: url(${Bar});
    background-position: center;
    background-size: cover;
    padding: 30px 0;
    .bar-grid {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    h3 {
      margin: 0;
      padding: 0;
      font-size: 24px;
      text-transform: uppercase;
      color: white;
    }
  }

  .copyright {
    text-align: center;
    padding: 10px 0;
    background: var(--heading);
    p {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 30px;

      /* or 187% */
      letter-spacing: 0.2px;

      color: white;
      margin: 0;
    }
  }

  @media only screen and (max-width: 810px) {
    .grid {
      grid-gap: 100px;
    }
    .bar {
      background-position: left;
    }
  }
`;

const FOOTERQUERY = graphql`
  {
    settings: sanitySiteSettings {
      phone
    }
  }
`;

export default function Footer({ companyName, footerText, services }) {
  const { settings } = useStaticQuery(FOOTERQUERY);

  return (
    <FooterStyles>
      <FooterLogos />

      <div className="bar">
        <div className="container bar-grid">
          <h3>START EXECUTING YOUR PROJECT</h3>
          <a href={`tel:${settings.phone}`}>
            <CallToAction>Call Us Now</CallToAction>
          </a>
        </div>
      </div>

      <div className="container grid">
        <div className="column column1">
          <img src={icon} alt="" loading="lazy" />
          <p>{footerText}</p>
        </div>

        {/* list out services here */}
        <div className="column column2">
          <h3>Services</h3>
          {services.map((service, i) => (
            <li key={`${i}-${service.id}`}>
              <Link to={`/${service.slug.current}`}>{service.name}</Link>
            </li>
          ))}
        </div>

        <div className="column column3">
          <h3>Who are we</h3>
          <ul>
            <li>
              <Link to="/about">About Us</Link>
            </li>

            <li>
              <Link to="/delivery">Delivery Centres</Link>
            </li>

            <li>
              <Link to="/sustainable">Sustainability</Link>
            </li>

            <li>
              <Link to="/team">Leadership Team</Link>
            </li>
          </ul>
        </div>

        <div className="column column4">
          <h3>Help</h3>
          <ul>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>

            <li>
              <Link to="/faqs">Frequently Asked Questions</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="copyright">
        <p>
          &copy; {new Date().getFullYear()} - {companyName} - All Rights
          Reserved
        </p>
      </div>
    </FooterStyles>
  );
}

Footer.propTypes = {
  companyName: PropTypes.string,
  footerText: PropTypes.string,
  services: PropTypes.array,
};
