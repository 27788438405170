/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Sidedrawer from './Sidedrawer';
import Burger from './Burger';

const NavStyles = styled.section`
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  .flex {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  nav {
    display: flex;
    justify-items: flex-end !important;
    align-items: center;
    width: auto;
    a {
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-weight: 600;
      text-decoration: none;
      padding: 16px;
      display: inline-block;
      color: var(--heading);
      transition: ease-in-out 0.08s;
      &:first-child {
        padding-left: 0;
      }
      &:hover {
        color: var(--burnt);
        box-shadow: inset 0 -4px 0 var(--accent);
      }
      &:last-of-type {
        padding-right: 0;
      }
    }

    .dropdown {
      display: inline-block;
      position: relative;
      color: var(--heading);
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;

      &:hover .dropdown-button {
        box-shadow: inset 0 -4px 0 var(--accent);
      }

      &:hover .dropdown-content {
        display: block;
        opacity: 1;
        pointer-events: all;
      }

      .dropdown-button {
        background: white;
        text-transform: uppercase;
        font-size: 14px;
        border: none;
        font-size: inherit;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        text-decoration: none;
        padding: 16px;
        color: var(--heading);
        transition: ease-in-out 0.08s;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:hover {
          color: var(--accent);
          box-shadow: inset 0 -4px 0 var(--accent);
        }
        svg {
          display: inline-block;
          height: 10px;
          padding-left: 5px;
        }
      }

      .dropdown-content {
        display: block;
        background: white;
        position: absolute;
        min-width: 250px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        display: none;
        opacity: 0;
        transition: 0.3s;
        font-family: 'Nunito Sans';

        a {
          padding: 18px;
          display: block;
          &:hover {
            box-shadow: none;
            background: var(--grey);
          }
        }
      }
    }
  }
  // ipad screens and smaller
  @media only screen and (max-width: 600px) {
    .flex {
      justify-content: flex-end;
    }
    //hide the main nac items on ipad screen or smaller
    nav {
      display: none;
    }
  }
`;

export default function Navigation() {
  const [isSidedrawerOpen, setIsSidedrawerOpen] = useState(false);

  const toggleSidedrawer = () => setIsSidedrawerOpen(!isSidedrawerOpen);

  return (
    <NavStyles>
      <div className="container flex">
        <nav>
          <Link to="/">Home</Link>

          <div className="dropdown">
            <button className="dropdown-button">Who are we</button>

            <div className="dropdown-content">
              <Link to="/about">About Us</Link>
              <Link to="/delivery">Delivery Centres</Link>
              <Link to="/sustainable">Sustainability</Link>
              <Link to="/team">Leadership Team</Link>
            </div>
          </div>

          <Link to="/services">Services</Link>

          <Link to="/news">News</Link>

          <div className="dropdown">
            <button className="dropdown-button">Insights</button>

            <div className="dropdown-content">
              <Link to="/faqs">Frequently Asked Questions</Link>
            </div>
          </div>
          <Link to="/contact">Contact</Link>
        </nav>

        <Burger click={toggleSidedrawer} />
        <Sidedrawer click={toggleSidedrawer} shown={isSidedrawerOpen} />
      </div>
    </NavStyles>
  );
}
