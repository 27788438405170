import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaRegArrowAltCircleUp } from 'react-icons/fa';

export default function ScrollToTopButton() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    // button is displayed after scrolling 300px down
    function handleWindowScrollButtonVisibility() {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    }

    // we need to check if this is being viewed on the server or the browser
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleWindowScrollButtonVisibility);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener(
          'scroll',
          handleWindowScrollButtonVisibility
        );
      }
    };
  });

  function handleScrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <>
      {showButton && (
        <ToTopButtonStyles
          type="button"
          className="scrollToTop"
          onClick={() => handleScrollToTop()}
        >
          <FaRegArrowAltCircleUp />
          Back to top
        </ToTopButtonStyles>
      )}
    </>
  );
}

const ToTopButtonStyles = styled.button`
  outline: none;
  border: none;
  background: var(--accent);
  padding: 5px 10px;
  color: white;
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  svg {
    margin-right: 10px;
  }
`;
